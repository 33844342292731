<template>
  <div>
    <px-landing-page-whatsapp
      class="mt-5 mb-12 mx-2"
      sub-title=""
      color="primary"
      :x-large="false"
      paddings="pt-5 pb-5"
      banner-cadastro-text-whats="Dúvida? Chame um consultor, será um prazer te ajudar!"
      container-class-prop="pl-8 pr-8 pt-2 pb-2"/>
    <px-loading-horizontal-cards :loading="loading"/>
    <px-fornecedor-ofertas-list v-if="isSearch"
                                :search-query-prop="searchQuery"/>
    <v-row dense no-gutters v-if="!loading">
      <template v-for="item in ofertas.content">
        <v-col cols="6" sm="" md="4"
               class="mt-8 d-flex align-start justify-center"
               :key="item.id"
               align="center">
          <px-oferta-card :oferta="item"/>
        </v-col>
      </template>
    </v-row>
    <v-row></v-row>
    <div v-if="(!ofertas.content || !ofertas.content.length) && !loading" align="center"
         class="headline mt-10">
      <px-ofertas-list-empty/>
    </div>
    <div class="text-center mt-12">
      <v-pagination
        v-model="page"
        :length="pagesLimit"
        :total-visible="6"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import paginationMixin from '@/mixins/pagination-mixin';
import { isEmpty } from 'lodash';
import EventService from '@/events';
import LandingPageWhatsapp from '@/modules/landing-page/LandingPageWhatsapp.vue';
import OfertaService from './oferta-service';
import OfertasListEmpty from './OfertasListEmpty.vue';
import FornecedorOfertasList from './FornecedorOfertasList.vue';

const LIMIT = 24;
const PAGE = 1;

export default {
  mixins: [paginationMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    sort: Object,
    searchQueryProp: {
      type: String,
      default: null,
    },
    pageParam: {
      type: [String, Number],
      default: null,
    },
    baseQueryProp: {
      type: String,
      default: null,
    },
    queryString: {
      type: String,
      default: null,
    },
    customQueryParam: {
      type: Object,
      default: null,
    },
    value: [Number, String],
  },
  data() {
    return {
      sortParam: this.$route.query.sort,
      baseQuery: null,
      searchQuery: null,
      loading: false,
      page: null,
      ofertas: {
        content: [],
        total: 0,
      },
      pagesLimit: 1,
      listOptions: {
        page: null,
        limit: LIMIT,
        offset: 0,
        filter: '',
        sort: '',
        sortDesc: true,
      },
      optionsList: {
        page: null,
        itemsPerPage: LIMIT,
        sortBy: '',
        sortDesc: false,
        multiSort: false,
      },
    };
  },
  methods: {
    buildQuery() {
      const sortQuery = (this.sort && this.sort.field) ? ` order by ${this.sort.field}` : '';
      let query = this.baseQuery || '';
      if (this.queryString) {
        query = query ? `${query} and ${this.queryString}` : this.queryString;
      }
      return `${query.trim()}${sortQuery.trim()}`;
    },
    buildQueryParams() {
      let params = {
        limit: this.listOptions.limit,
        offset: this.listOptions.offset,
      };
      if (this.searchQuery) {
        params.q = this.searchQuery;
        params.relevancia = true;
      }
      if (this.baseQuery || this.queryString || this.sort) {
        params.query = this.buildQuery();
      }
      if (this.customQueryParam) {
        params = { ...params, ...this.customQueryParam };
      }
      if (this.sort && this.sort.params) {
        params = { ...params, ...this.sort.params };
      }
      return params;
    },
    setPagesLimit() {
      this.pagesLimit = Math.ceil(this.ofertas.total / LIMIT);
    },
    checkEmptyResult() {
      if (!this.isSearch) {
        return;
      }
      if (isEmpty(this.ofertas.content)) {
        EventService.createAcessoTarget(axios, 'BUSCA_SEM_RESULTADO',
          { value: this.value }, this.value);
      }
    },
    doQuery() {
      this.loading = true;
      OfertaService.queryOfertas(this.buildQueryParams())
        .then(({ data }) => {
          this.ofertas = data;
          this.setPagesLimit();
          this.checkEmptyResult();
          this.checkEmptyPage();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkEmptyPage() {
      if (this.ofertas.content && this.ofertas.content.length) {
        return;
      }
      // checa se está em uma pagína além das disponíveis
      if (this.ofertas.total > 1 && this.page > 1) {
        this.resetPage();
        this.doQuery();
      }
    },
    onChangeOptions() {
      this.resetListOptions();
      this.doQuery();
    },
    onChangeSort() {
      this.doQuery();
    },
    onChangePage() {
      this.$emit('change-page', this.page);
    },
    resetListOptions() {
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
    },
    init() {
      this.setupPagination();
      this.setupBaseQuery();
      this.doQuery();
    },
    setupBaseQuery() {
      this.searchQuery = this.searchQueryProp;
      this.baseQuery = this.baseQueryProp;
    },
    setupPagination() {
      this.page = this.pageParam || PAGE;
      this.optionsList.page = this.pageParam || PAGE;
      this.resetListOptions();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
      this.page = PAGE;
    },
  },
  watch: {
    sort(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.onChangeSort();
    },
    page(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.optionsList.page = newValue;
      window.scrollTo(0, 0);
      this.onChangePage();
      this.onChangeOptions();
    },
    queryString(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.resetPage();
      this.doQuery();
    },
  },
  created() {
    this.init();
  },
  components: {
    pxOfertasListEmpty: OfertasListEmpty,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
    pxFornecedorOfertasList: FornecedorOfertasList,
  },
};
</script>

<style scoped>
.line-filter {
  background-color: #ebedf0;
}
</style>

<template>
    <px-oferta-sort-options v-model="localValue"
                            :is-search="isSearch"
                            @change="onChange"/>
</template>

<script>
import OfertaSortOptions from './OfertaSortOptions.vue';

export default {
  props: {
    value: Object,
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortOption: null,
    };
  },
  methods: {
    onChange() {
      this.$emit('change');
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  components: {
    pxOfertaSortOptions: OfertaSortOptions,
  },
};
</script>

<template>
  <div class="mr-2">
    <div class="body-2 text--primary">
      <px-filter-navegacao-all :type="type"
                               :value="value"
                               :query="query"
                               :is-search="isSearch"
                               :is-favoritos="isFavoritos"/>
      <template v-if="isLogged">
        <px-filtro-selecao-fornecedor :filters="filters"
                                      :query="query"
                                      :is-search="isSearch"
                                      :is-favoritos="isFavoritos"
                                      @change="onChangeFilterFornecedor"/>
        <px-filtro-promocao class="mt-5"
                            :filters="filters"
                            @change="onChangeFilterPromocao"/>
        <px-filtro-preco    class="mt-5"
                            :filters="filters"
                            @change="onChangeFilterPreco"/>
      </template>
      <template v-else>
        <px-go-to-login-block class="mt-3">
          <template v-slot:activator="{ on }">
          <span class="body-2">
            <a v-on="on">Faça login</a> para ver mais filtros
          </span>
          </template>
        </px-go-to-login-block>
      </template>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import FilterNavegacaoAll from './filters/FilterNavegacaoAll.vue';
import FiltroSelecaoFornecedor from './filters/FiltroSelecaoFornecedor.vue';
import FiltroPromocao from './filters/FiltroPromocao.vue';
import FiltroPreco from './filters/FiltroPreco.vue';

export default {
  props: {
    filters: Object,
    type: {
      type: String,
      required: true,
    },
    isSearch: {
      type: Boolean,
      required: true,
      default: false,
    },
    isFavoritos: {
      type: Boolean,
      required: true,
      default: false,
    },
    value: [Number, String],
    query: String,
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    onChangeFilterFornecedor(selected) {
      const items = selected.map(item => item.id);
      const event = {
        property: 'fornecedor',
        value: items,
      };
      if (isEmpty(items)) {
        this.$emit('remove', event);
        return;
      }

      this.$emit('change', event);
    },
    onChangeFilterPromocao(selected) {
      const event = {
        property: 'promocao.ativo',
        value: selected,
      };
      if (!selected) {
        this.$emit('remove', event);
        return;
      }

      this.$emit('change', event);
    },
    onChangeFilterPreco(value) {
      const event = {
        property: 'precoAVista',
        min: value.min,
        max: value.max,
      };
      if (!event.min && !event.max) {
        this.$emit('remove', event);
        return;
      }

      this.$emit('change', event);
    },
  },
  components: {
    pxFilterNavegacaoAll: FilterNavegacaoAll,
    pxFiltroSelecaoFornecedor: FiltroSelecaoFornecedor,
    pxFiltroPromocao: FiltroPromocao,
    pxFiltroPreco: FiltroPreco,
  },
};
</script>

<template>
  <v-skeleton-loader type="heading" :loading="loading">
    <v-row class="ml-3">
      <v-col cols="12" class="caption text--primary"
             v-if="!isSearch && title">
        <router-link to="/home">
          Home
        </router-link>
        &nbsp;>
        <template v-for="(item, index) in breadcrumbs">
          <a v-if="item.handler"
                        :key="item.description" @click="item.handler(item.id)">
            {{ item.description }}
          </a>
          <span v-if="!item.handler" :key="`sp-${item.description}`">{{ item.description }}</span>
          <span :key="`div-${item.description}`" v-if="index < breadcrumbs.length - 1">
            &nbsp;>&nbsp;
          </span>
        </template>
      </v-col>
    </v-row>
    <v-row class="ml-3 headline text--primary font-weight-bold">
      <v-col v-title v-meta-description cols="12" sm="6">
        <span v-if="isSearch">
          Resultados para {{ value }}
        </span>
        <span v-if="!isSearch && hasNullPath">
          {{ simpleDescription }}
        </span>
        <span v-if="!isSearch && title">
          {{ title.descricao }}
        </span>
      </v-col>
      <v-col cols="12" sm="6" class="text-right body-2 text--primary" v-if="!isMobile">
        <px-oferta-list-sort-block v-model="sort"
                                   :is-search="isSearch"
                                   @change="onChangeSort"/>
      </v-col>
    </v-row>
  </v-skeleton-loader>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import { TYPES } from './types';
import OfertaService from './oferta-service';
import { goToOfertasDepartamento, goToOfertasGrupo } from '../../services/route-oferta-service';
import OfertaListSortBlock from './OfertaListSortBlock.vue';

export default {
  mixins: [gridMixin],
  props: {
    sortProp: {
      type: Object,
      required: true,
    },
    typeProp: {
      type: String,
      required: true,
    },
    value: [Number, String],
  },
  data() {
    return {
      sort: null,
      type: null,
      title: null,
      loading: false,
      breadcrumbs: [],
    };
  },
  computed: {
    isSearch() {
      return TYPES.search.id === this.type;
    },
    hasNullPath() {
      return !TYPES[this.type].path;
    },
    simpleDescription() {
      return TYPES[this.type].description;
    },
    isGrupo() {
      return TYPES.g.id === this.type;
    },
    isCategoria() {
      return TYPES.c.id === this.type;
    },
  },
  methods: {
    setupBreadcrumbs() {
      if (!this.title) {
        return;
      }
      this.breadcrumbs = [];
      if (TYPES.d.id === this.type) {
        this.breadcrumbs.push({
          description: this.title.descricao,
          handler: null,
        });
      }
      if (this.isGrupo) {
        this.breadcrumbs.push({
          description: this.title.departamento.descricao,
          handler: goToOfertasDepartamento,
          id: this.title.departamento.id,
        });
        this.breadcrumbs.push({
          description: this.title.descricao,
          handler: null,
        });
      }
      if (this.isCategoria) {
        this.breadcrumbs.push({
          description: this.title.grupo.departamento.descricao,
          handler: goToOfertasDepartamento,
          id: this.title.grupo.departamento.id,
        });
        this.breadcrumbs.push({
          description: this.title.grupo.descricao,
          handler: goToOfertasGrupo,
          id: this.title.grupo.id,
        });
        this.breadcrumbs.push({
          description: this.title.descricao,
          handler: null,
        });
      }
    },
    getDescription() {
      const typeProperties = TYPES[this.type];
      if (typeProperties && typeProperties.path) {
        this.loading = true;
        OfertaService.getCompleteEntity(typeProperties.path, this.value)
          .then(({ data }) => {
            this.title = data;
            this.setupBreadcrumbs();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onChangeSort() {
      this.$emit('change', this.sort);
    },
  },
  created() {
    this.sort = this.sortProp;
    this.type = this.typeProp;
    if (this.isSearch) {
      return;
    }
    this.getDescription();
  },
  components: {
    pxOfertaListSortBlock: OfertaListSortBlock,
  },
};
</script>

<template>
  <div>
    <div class="title">
      {{ title }}
    </div>
    <v-skeleton-loader
      :loading="loading"
      type="list-item@3"
    >
      <v-list dense>
      <v-list-item v-for="option in options" :key="option.id" class="list-item-filter pl-1">
        <v-list-item-content>
          <a @click="onClickOption(option)" class="link-group text--primary">
            {{ option.description }}
          </a>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    </v-skeleton-loader>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Filtro',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: Array,
  },
  methods: {
    onClickOption(option) {
      option.clickFunction(option.id);
    },
  },
};
</script>

<style scoped>
.list-item-filter {
  min-height: 25px;
}
</style>

<template>
  <v-menu
    left
    close-on-content-click
    v-model="menuOpened"
    :nudge-width="300"
    max-width="350"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn outlined
             large
             class="mx-1 rounded-tag-small normal-btn-text"
             v-on="on">
        <v-icon left>
          mdi-sort-variant
        </v-icon>
        {{ description }}
        <v-icon>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item v-for="option in sortOptionsComplete"
                     :key="option.description"
                     @click="onSelect(option)">
          <v-list-item-content>
            {{ option.description }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { setQueryParam } from '../../services/route-oferta-service';

export default {
  props: {
    value: Object,
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    menuOpened: false,
  }),
  computed: {
    ...mapGetters('ofertasList', ['sortOptions', 'paramName']),
    description() {
      return this.localValue ? this.localValue.description : '';
    },
    sortOptionsComplete() {
      return this.sortOptions.filter((item) => {
        if (this.isSearch) {
          return item;
        }
        return !item.params;
      });
    },
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  methods: {
    close() {
      this.menuOpened = false;
    },
    onSelect(option) {
      this.localValue = option;
      setQueryParam(this.paramName, option.field);
      this.$emit('change');
    },
  },
  created() {
    if (!this.localValue) {
      const [value] = this.sortOptions;
      this.localValue = value;
      setQueryParam(this.paramName, value.field);
      return;
    }
    setQueryParam(this.paramName, this.localValue.field);
  },
};
</script>

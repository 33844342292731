<template>
  <div>
    <div v-if="filter">
      <px-filter-navegacao :title="filter.title" :options="options" :loading="loading"/>
      <v-divider class="mt-3 mb-3 bigger-divider"/>
    </div>
  </div>
</template>

<script>
import { goToOfertasGrupo, goToOfertasCategoria } from '../../../services/route-oferta-service';
import OfertaService from '../oferta-service';
import FilterNavegacao from './FilterNavegacao.vue';

const FILTER_TYPES = {
  d: {
    title: 'Grupos',
    path: '/grupos/completos',
    linkTo: null,
    descriptionField: 'descricao',
    additionalQuery: 'produtos = true',
    clickFunction(value) {
      goToOfertasGrupo(value);
    },
  },
  g: {
    title: 'Categorias',
    path: '/categorias/completos',
    linkTo: null,
    descriptionField: 'descricao',
    additionalQuery: 'produtos = true',
    clickFunction(value) {
      goToOfertasCategoria(value);
    },
  },
  search: {
    title: 'Categorias',
    path: '/ofertas/categorias',
    linkTo: null,
    descriptionField: 'descricao',
    additionalQuery: 'categoria.produtos = true',
    clickFunction(value) {
      goToOfertasCategoria(value);
    },
  },
};

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isFavoritos: {
      type: Boolean,
      default: false,
    },
    value: [Number, String],
    query: String,
  },
  data() {
    return {
      filter: null,
      loading: false,
      options: [],
    };
  },
  methods: {
    buildItem(item) {
      return {
        id: item.id,
        description: item[this.filter.descriptionField],
        linkTo: this.filter.linkTo,
        clickFunction: this.filter.clickFunction,
      };
    },
    buildQuery() {
      let { query } = this;

      if (this.isSearch) {
        return {
          q: query,
        };
      }

      if (this.filter.additionalQuery) {
        query = `${query} and ${this.filter.additionalQuery}`;
      }
      return { query };
    },
    getItems() {
      this.loading = true;
      const params = this.buildQuery();

      if (this.isFavoritos) {
        params.favorito = true;
      }

      OfertaService.getEntityFilter(this.filter.path, params)
        .then(({ data }) => {
          this.options = data.map(this.buildItem);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setupFilter() {
      if (this.isSearch || this.isFavoritos) {
        this.filter = FILTER_TYPES.search;
        this.getItems();
        return;
      }

      const filterType = FILTER_TYPES[this.type];
      if (!filterType) {
        return;
      }
      this.filter = filterType;
      this.getItems();
    },
  },
  created() {
    this.setupFilter();
  },
  components: {
    pxFilterNavegacao: FilterNavegacao,
  },
};
</script>

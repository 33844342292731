<template>
  <v-container class="mkt-content-container mt-5">
    <div class="mt-3 mb-5">
      <px-oferta-title :type-prop="type"
                       :sort-prop="sort"
                       @change="onChangeSort"
                       :value="value"/>
    </div>
    <v-row v-if="isMobile">
      <v-col cols="12">
        <px-ofertas-filters-mobile :type="type"
                                   :value="value"
                                   :query="query"
                                   :filters="filters"
                                   :is-search="isSearch"
                                   :is-favoritos="isFavoritos"
                                   @change="onAddFilter"
                                   @remove="onRemoveFilter"/>
        <px-oferta-list-sort-block v-model="sort"
                                   :is-search="isSearch"/>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12" sm="2" md="3" class="mt-6" v-if="!isMobile">
        <px-ofertas-filters-adapter :type="type"
                            :value="value"
                            :query="query"
                            :filters="filters"
                            :is-search="isSearch"
                            :is-favoritos="isFavoritos"
                            @change="onAddFilter"
                            @remove="onRemoveFilter"/>
      </v-col>
      <v-col cols="12" sm="10" md="9">
        <px-ofertas-list :type="type"
                         :value="value"
                         :sort="sort"
                         :page-param="page"
                         :base-query-prop="baseQuery"
                         :search-query-prop="searchQuery"
                         :query-string="queryFilterString"
                         :custom-query-param="customQueryParam"
                         :is-search="isSearch"
                         @change-page="onChangePage"/>
      </v-col>
    </v-row>
    <v-row class="mt-12" v-if="!isLogged">
      <v-col cols="12">
        <px-banner-cadastro-email/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import { TYPES } from '@/modules/ofertas/types';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { last } from 'lodash';
import EventService from '@/events';

// eslint-disable-next-line import/no-cycle
import OfertaListMixin from '@/mixins/oferta-list-mixin';
// eslint-disable-next-line import/no-cycle
import { setQueryParam } from '../../services/route-oferta-service';

import OfertaTitle from './OfertaTitle.vue';
import OfertasList from './OfertasList.vue';
import OfertasFiltersAdapter from './OfertasFiltersAdapter.vue';
import OfertaListSortBlock from './OfertaListSortBlock.vue';
import OfertasFiltersMobile from './OfertasFiltersMobile.vue';

const PAGE = 1;

export default {
  mixins: [OfertaListMixin, gridMixin],
  data() {
    return {
      sortParam: this.$route.query.sort,
      sort: null,
      searchQuery: null,
      baseQuery: null,
      customQueryParam: null,
      pageParam: this.$route.query.page,
      page: PAGE,
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    ...mapGetters('ofertasList', ['sortOptions', 'sortOption', 'defaultSort']),
    type() {
      return this.$route.params.type;
    },
    value() {
      return this.$route.params.value;
    },
    queryFilter() {
      return this.$route.query.q;
    },
    location() {
      return `/${this.type}/${this.value}`;
    },
    isSearch() {
      return this.type === TYPES.search.id;
    },
    isFavoritos() {
      return this.type === TYPES.f.id;
    },
    query() {
      return this.searchQuery || this.baseQuery;
    },
  },
  methods: {
    onChangeSort(sort) {
      this.sort = sort;
    },
    setupSort() {
      if (this.sortParam) {
        this.sort = this.sortOption(this.sortParam);
        return;
      }
      if (this.isSearch) {
        this.sort = last(this.sortOptions);
        return;
      }
      this.sort = this.defaultSort;
    },
    setupBaseQuery() {
      if (this.isSearch) {
        this.searchQuery = this.value;
        return;
      }
      this.baseQuery = TYPES[this.type].queryFieldBuilder(this.value);
    },
    setupCustomQueryParam() {
      this.customQueryParam = TYPES[this.type].customQueryParam;
    },
    fireEvent() {
      EventService.createAcessoTarget(axios, TYPES[this.type].eventResource,
        { value: this.value }, this.value);
    },
    onChangePage(newPage) {
      if (this.page === newPage) {
        return;
      }
      this.page = newPage;
      setQueryParam('page', newPage);
    },
    setupInitialPage() {
      if (this.pageParam) {
        this.page = Number(this.pageParam);
      }
    },
    init() {
      this.setupInitialPage();
      this.setupCustomQueryParam();
      this.setupSort();
      this.setupBaseQuery();
      this.setupQueryFilter();
      this.fireEvent();
    },
  },
  created() {
    if (!this.type || !this.value) {
      this.$router.push({ name: 'public.home' });
    }
    this.init();
  },
  watch: {
    // todo: se precisar dar refresh nos componentes, verificar se é a melhor forma de refresh
    location(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      // window.location.reload();
      this.$router.go(this.$router.currentRoute);
    },
  },
  components: {
    pxOfertaTitle: OfertaTitle,
    pxOfertasList: OfertasList,
    pxOfertasFiltersAdapter: OfertasFiltersAdapter,
    pxOfertaListSortBlock: OfertaListSortBlock,
    pxOfertasFiltersMobile: OfertasFiltersMobile,
  },
};
</script>
